.pageGrid {
  grid-template-columns: 1fr 1fr;
}

.pageGrid > div > div {
  padding-bottom: 2rem;
}

.leftCol {
  border-right: 1px solid;
}

.matchupLogo {
  width: 40%;
}

.sectionHeader {
  border-bottom: 1px solid;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: bold;
}

.resultBar {
  position: relative;
  cursor: default;
}

.subText {
  font-size: x-small;
  text-align: center;
}

.popup {
  display: none;
  position: absolute;
  top: 110%;
  padding: 0.5rem;
  background-color: var(--background);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid;
  font-size: small;
  z-index: 10;
  white-space: nowrap;
}

.right {
  left: 80%;
}

.left {
  right: 80%;
}

.hoverWrapper:hover .resultBar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8) !important;
}

.hoverWrapper:hover .popup {
  display: block;
}

.opponent:hover .popup {
  display: block;
}

.statContainer {
  display: contents;
}

.statLeft {
  padding: 0.15rem 0;
  margin: 1px 0;
  border-radius: 6px 0 0 6px;
  white-space: nowrap;
  border: 1px solid var(--color);
  color: black;
  border-right: none;
}

.statRight {
  padding: 0.15rem 0;
  margin: 1px 0;
  border-radius: 0 6px 6px 0;
  white-space: nowrap;
  border: 1px solid var(--color);
  color: black;
  border-left: none;
}

.better {
  background-color: rgba(23, 145, 8) !important;
}

.good {
  background-color: rgb(58, 171, 56) !important;
}

.okay {
  background-color: rgb(119, 204, 109) !important;
}

.fine {
  background-color: rgb(179, 244, 171) !important;
}

.neutral {
  background-color: rgba(211, 211, 211, 0.52) !important;
}

.notGood {
  background-color: rgb(255, 195, 196) !important;
}

.notOkay {
  background-color: rgb(255, 168, 155) !important;
}

.bad {
  background-color: rgb(255, 124, 104) !important;
}

.worse {
  background-color: rgb(255, 80, 53) !important;
}

.bestBookWrapper {
  display: contents;
}

.keyStats .popup {
  left: unset;
  transform: translateX(-50%);
}

.coverButton {
  background: none;
  border-radius: 8px;
  padding: 0.25rem;
  margin: 0 5px;
  cursor: pointer;
}

.selected {
  background-color: rgba(216, 216, 216, 0.754);
}

@media (max-width: 750px) {
  .pageGrid {
    grid-template-columns: 1fr;
  }

  .leftCol,
  .rightCol {
    max-height: unset;
    overflow: unset;
  }

  .leftCol {
    border-right: none;
  }

  .sectionHeader {
    border-top: 1px solid;
  }

  .keyStats {
    font-size: small;
  }

  .matchupLogo {
    width: 60%;
  }
}
