.cardTable td,
.cardTable th {
  width: 70px !important;
  min-width: 70px !important;
  height: 20px !important;
}

.cardTable thead tr:first-of-type th {
  text-decoration: underline;
}

.cardTable td {
  background-color: rgba(170, 170, 170, 0.394);
}

.cardRow table:first-of-type {
  margin-left: auto;
}

.cardRow table:last-of-type {
  margin-right: auto;
}

@media (max-width: 700px) {
  .cardTable th,
  .cardTable td {
    min-width: unset !important;
  }

  .cardTable {
    align-self: center;
  }

  .cardRow {
    padding-top: 0;
    justify-content: center;
  }
}
