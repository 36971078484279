.searchWrapper {
  display: flex;
}

.searchWrapper input {
  width: 100%;
  max-width: 100%;
  padding: 3px;
  border: 2px solid var(--color);
}
