/* Basic matchup row table formatting */
.matchupRow table {
  padding-right: 10px;
  font-size: small;
}

/* Contains MatchupRows and allows scroll */
.matchupsContainer {
  overflow-x: auto;
}

.matchupRow .bestBookWrapper:last-of-type table {
  padding-right: unset;
}

/* Matchup table data cell color */
.matchupRow table td {
  background-color: var(--table-background);
}

/* Matchup table header and data cell sizing */
.matchupRow table td,
.matchupRow table th {
  width: 100px;
  min-width: 94px;
  height: 20px;
}

.doubleCell {
  width: 200px !important;
  min-width: 188px !important;
  font-weight: bold;
}

.matchupHeader {
  display: grid;

  grid-template-columns: 1fr 1fr;
  align-items: baseline;
}

.matchupHeader > :nth-child(2) {
  text-align: right;
}

.grid {
  display: grid;
  grid-template-columns: 0.2fr 0.2fr 1fr 0.25fr;
  align-items: center;
  justify-content: center;
}

.grid > span {
  align-self: center;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

/* Stick filter options and logo at the top of page on scroll */
.stickyTop {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--background);
  border-bottom: 2px solid;
}

.winner {
  background-color: rgb(225, 191, 2) !important;
  color: black;
}

.push {
  background-color: rgb(160, 199, 220) !important;
}

/* Vincerix logo sizing */
.logoLink img {
  width: auto;
  max-width: 100%;
  max-height: 30px;
}

.matchupRow {
  display: flex;
  padding: 10px 0;
  margin: 0 10px;
  cursor: pointer;
}

.subfont {
  font-size: xx-small;
}

.matchupRow table tbody {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.table tbody {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dynamicFlex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.filterOptions {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.filterOptions > div {
  max-height: 27px;
}

.filterOptions input {
  font-size: 15px;
}

.textLeft,
.teamName {
  text-align: left !important;
}

.textRight {
  text-align: right;
}

.italics {
  font-style: italic;
  font-weight: bold;
}

.paddingRight {
  padding-right: 5px;
}

.better {
  background-color: rgb(217, 234, 211);
}

.bookLogo {
  height: 17px;
}

@media (max-width: 700px) {
  .rowContainer {
    border-bottom: 1px dotted;
  }

  .matchupRow {
    flex-wrap: wrap;
  }

  .matchupsContainer .rowContainer:last-child {
    border-bottom: none;
  }

  .matchupRow table {
    padding: 0 0px;
  }

  .desktop {
    display: none;
  }

  .matchupRow table {
    width: 50%;
  }

  .matchupRow table th,
  .matchupRow table td,
  .bestBookWrapper table td,
  .bestBookWrapper table th {
    min-width: unset;
  }

  .bestBookWrapper {
    display: contents;
  }

  .doubleCell {
    min-width: unset !important;
    width: unset !important;
  }

  .dynamicFlex {
    flex-direction: column;
  }

  .filterOptions {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 5px;
  }

  .textLeft {
    text-align: center !important;
  }

  .matchupHeader > :nth-child(2) {
    text-align: unset;
  }
}
