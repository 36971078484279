.headerContainer {
  display: flex;
  padding: 10px 0;
  font-size: x-large;
  font-weight: bold;
  border-bottom: 2px solid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dynamicFlex {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-self: center;
  z-index: 10;
}

.logoLink {
  text-decoration: none;
  color: inherit;
}

.logoLink img {
  width: auto;
  max-width: 100%;
  max-height: 45px;
}

.nav {
  display: flex;
  gap: 10px;
}

@media (max-width: 800px) {
  .nav {
    /* flex-direction: column; */
    font-size: medium;
  }

  .logoLink img {
    max-height: 30px;
  }
}
