:root[data-theme="light"] {
  color-scheme: light;
  --color: #111111;
  --color-rgb: 0, 0, 0;
  --background: #fff;
  --table-background: rgb(239, 239, 239);
  --table-accent: lightgray;
  --odds-background: white;
  --link: blue;
  --unvisited-link-color: blue;
  --visited-link-color: blue;
}
:root[data-theme="dark"] {
  color-scheme: dark;
  --color: #ffffff;
  --color-rgb: 239, 239, 239;
  --background: #333;
  --table-background: rgb(82, 82, 82);
  --table-accent: gray;
  --odds-background: gray;
  --link: lightskyblue;
  --unvisited-link-color: lightskyblue;
  --visited-link-color: #a287bb;

  select {
    color: var(--color);
  }
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1200px;
  width: 98%;
  padding-bottom: 10px;
  color: var(--color);
  background: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--unvisited-link-color);
}

a:visited {
  color: var(--visited-link-color);
}
