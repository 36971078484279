.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

/* Table Layout Formatting */
.tableWrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 80vh;
}

.stickyTable {
  border-spacing: 0;
  width: 100%;
  font-size: small;
  background-color: var(--background);
}

.stickyTable th,
.stickyTable td {
  padding: 2px 0.5rem;
  white-space: nowrap;
}

.stickyRow {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: var(--background);
  z-index: 2;
}

.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 0 4px;

  background-color: var(--background);
}

/* Cosmetic Formatting */
.stickyTable tr td:not(:last-child) {
  border-right: 1px solid var(--table-accent);
}

.stickyTable tr:first-of-type th:not(:last-child),
.stickyTable tr:last-of-type th:nth-child(6),
.stickyTable tr td:nth-child(6),
.stickyTable tr th:nth-child(8),
.stickyTable tr td:nth-child(8),
.stickyTable tr th:nth-child(12),
.stickyTable tr td:nth-child(12) {
  border-right: 1px solid var(--color);
}

.stickyTable thead tr th {
  border-bottom: 1px solid;
}

.stickyTable tbody tr:nth-child(odd) td {
  background-color: var(--table-background);
}

.textLeft {
  text-align: left;
}

.stickyTable tr .stickyColumn:first-child {
  font-weight: bold;
  text-align: left;
  border-right: 3px solid !important;
}

.subfont {
  font-size: xx-small;
}

.selected {
  border: 1px solid;
  background-color: var(--table-accent);
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.3);
}

.stickyTable thead span {
  padding: 0 10px;
}

.sortable {
  text-decoration: underline;
  cursor: pointer;
}

.better {
  background-color: #79d279 !important;
  color: black;
}

.worse {
  background-color: #ff6666ee !important;
  color: black;
}

.nameCol span {
  text-align: center;
}

.teamName {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}

.faqItem {
  position: relative;
  font-size: small;
  text-align: left;
  width: fit-content;
}

.question {
  cursor: pointer;
  margin: 5px 0;
  border-radius: 5px;
  color: var(--link);
}

.answer {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 300%;
  padding: 10px;
  background-color: var(--background);
  border: 1px solid;
  border-radius: 5px;
  z-index: 100;
  font-size: small;
}

.faqItem:hover .answer {
  display: block;
}

@media (max-width: 800px) {
  .tableWrapper {
    max-height: 100vh;
  }

  .stickyTable {
    font-size: xx-small;
  }

  .stickyColumn {
    max-width: 30vw;
    overflow-x: hidden;
  }

  .banner h1 {
    font-size: large;
  }
}
