/* Book selector wrapper */
.multiSelectDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Book selector dropdown button */
.dropdownHeader {
  display: flex;
  align-items: center;
  padding: 3px;
  border: 2px solid;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
}

/* 3-D header when hovering */
.dropdownHeader:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

/* Dropdown arrow */
.dropdownHeader::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--color);
  margin-right: 5px;
  margin-left: 5px;
}

/* Rotate arrow if menu is open */
.dropdownHeader.isOpen::after {
  transform: rotate(180deg); /* Rotate the arrow 180 degrees (upside down) */
}

/* Book names container */
.dropdownOptions {
  position: absolute;
  left: 0;
  border: 1px solid;
  background-color: var(--background);
  min-height: 150px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  font-size: 15px;
  max-height: 50vh;
}

/* Book name row in dropdown */
.option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  user-select: none;
  min-height: 1.25rem;
  text-align: center;

  align-items: center;
  border-radius: 5px;
  margin: 0.5rem;
}

.selected {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Highlight book name if hovering on it */
.option:hover {
  background-color: lightgray;
}

/* Book name display */
.optionName {
  border: 1px black solid;
  border-radius: 10px;
  width: 80%;
}

/* Selected book name indicator */
.checkmark {
  color: black;
  font-size: 0.8rem;
  margin-left: 5px;
}

.bookLogo {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 700px) {
  .option:hover {
    background-color: unset;
  }
}
